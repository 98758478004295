import React, { useCallback, useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Prismic from 'prismic-javascript';
import PrismicDOM from 'prismic-dom';
import { Col, Row } from 'antd';
import Lightbox from 'react-image-lightbox';
import { withTrans } from '../i18n/withTrans';
import AppLayout from '../components/AppLayout';
import SEO from '../components/seo';

const apiEndpoint = 'https://mattines.cdn.prismic.io/api/v2';

const ArticlesGallery = ({ articles }) => {
	const [currentImage, setCurrentImage] = useState(0);
	const [viewerIsOpen, setViewerIsOpen] = useState(false);

	const openLightbox = useCallback((event, index) => {
		setCurrentImage(index);
		setViewerIsOpen(true);
	}, []);

	return (
		<>
			<Row gutter={32} align="stretch">
				{articles.map((v, i) => (
					<Col key={i} xs={24} md={12} lg={8} xl={6} style={{ textAlign: 'center' }}>
						{v.article_title.length ? <h3>{v.article_title[0].text}</h3> : null}
						{v.infos_article.length ? <div className="content" dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(v.infos_article) }} /> : null}

						<div className="preview">
							<img
								onClick={event => openLightbox(event, i)}
								src={v.article_image.tmb.url} alt={v.article_title.length ? v.article_title[0].text : ''}
								style={{
									objectFit: 'cover',
									objectPosition: 'top',
									maxHeight: 240,
									width: '100%',
									cursor: 'pointer'
								}}
							/>
						</div>
					</Col>
				))}
			</Row>

			{viewerIsOpen ? (
				<Lightbox
					mainSrc={articles[currentImage].article_image.url}
					nextSrc={articles[(currentImage + 1) % articles.length]}
					prevSrc={articles[(currentImage + articles.length - 1) % articles.length]}
					onCloseRequest={() => setViewerIsOpen(false)}
					onMovePrevRequest={() => setCurrentImage((currentImage + articles.length - 1) % articles.length)}
					onMoveNextRequest={() => setCurrentImage((currentImage + 1) % articles.length)}
				/>
			) : null}
		</>
	);
};

const MediasPage = () => {
	const [ video, setVideo ] = useState({ titre: '', legume_image: '', sous_titre: '', paragraph: '' });
	const [ videos, setVideos ] = useState([]);
	const [ article, setArticle ] = useState({ titre: '', legume_image: '', sous_titre: '', paragraph: '' });
	const [ articles, setArticles ] = useState([]);

	const data = useStaticQuery(graphql`
	    query {
	      imageMedias: file(relativePath: { eq: "medias.jpg" }) {
	        childImageSharp {
	          fluid(maxWidth: 1920) {
	            ...GatsbyImageSharpFluid_withWebp
	          }
	        }
	      }
	    }
	`);

	useEffect(() => {
		Prismic.getApi(apiEndpoint).then(
			api => api.getByID('X4cAbhEAACcAIqc3', { lang : 'fr-ch' })
		).then(response => {
			setVideo({
				titre: response.data.video_titre,
				legume_image: response.data.video_image_legume,
				sous_titre: response.data.video_sous_titre,
				paragraph: response.data.video_paragraph
			});
			setVideos(response.data.video);

			setArticle({
				titre: response.data.articles_titre,
				legume_image: response.data.articles__image_legume,
				sous_titre: response.data.articles_sous_titre,
				paragraph: response.data.articles_paragraph
			});
			setArticles(response.data.articles);
		});
	}, []);

	return (
		<AppLayout>
			<SEO title="Médias" />

			<div className="hero-image">
				<Img fluid={data.imageMedias.childImageSharp.fluid} />
			</div>

			<div>
				<div className="page-section">
					<div className="section-title">
						<h2>
							{video.titre.length ? video.titre[0].text : ''}
							{video.legume_image.url ? (
								<img src={video.legume_image.url} alt={video.titre.length ? video.titre[0].text : ''} />
							) : null}
						</h2>
						<div className="section-title-line" />
					</div>

					<div className="section-content">
						<div className="section-content-text">
							{video.sous_titre.length ? <h3>{video.sous_titre[0].text}</h3> : null}
							{video.paragraph.length ? <div dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(video.paragraph) }} /> : null}
						</div>
					</div>

					<div className="section-list">
						<Row gutter={32} align="stretch">
							{videos.map((v, i) => (
								<Col key={i} xs={24} md={24} lg={12} style={{ textAlign: 'center' }}>
									{v.video_title.length ? <h3>{v.video_title[0].text}</h3> : null}
									{v.video_paragraph.length ? <div className="content" dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(v.video_paragraph) }} /> : null}

									<div className="preview">
										<iframe
											width="640"
											height="360"
											src={`https://www.youtube-nocookie.com/embed/${v.video1.embed_url.split('?v=')[1]}`}
											frameBorder="0"
											allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowFullScreen={true}
										/>
									</div>
								</Col>
							))}
						</Row>
					</div>
				</div>

				<div className="page-section">
					<div className="section-title">
						<h2>
							{article.titre.length ? article.titre[0].text : ''}
							{article.legume_image.url ? (
								<img src={article.legume_image.url} alt={article.titre.length ? article.titre[0].text : ''} />
							) : null}
						</h2>
						<div className="section-title-line" />
					</div>

					<div className="section-content">
						<div className="section-content-text">
							{article.sous_titre.length ? <h3>{article.sous_titre[0].text}</h3> : null}
							{article.paragraph.length ? <div dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(article.paragraph) }} /> : null}
						</div>
					</div>

					<div className="section-list">
						<ArticlesGallery articles={articles} />
					</div>
				</div>
			</div>
		</AppLayout>
	);
};

export default withTrans(MediasPage);
